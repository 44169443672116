<template>
    <fragment>

        <a-row class="buttons-class">
            <el-button style="float: left;margin: 0 5px" size="small" v-if="showCloseButton" @click="closeComponent()">返回</el-button>

            <el-button
                    v-for="(button,buttonIndex) in tableButtons"
                    :key="buttonIndex"
                    style="float: left;margin: 0 5px"
                    size="small"
                    :type="button.buttonType"
                    @click="buttonHandle(button)">{{button.name}}</el-button>

            <el-button style="float: left;margin: 0 5px" size="small" type="success" @click="refresh()">刷新</el-button>
            <el-button style="float: left;margin: 0 5px" size="small" v-if="hasSearchStructure" title="搜索&查询" :type="searchButtonColor" icon="el-icon-search" circle @click="showSearch()"></el-button>
            <el-upload
                    :show-file-list="false"
                    v-if="hasImport"
                    action=""
                    accept=".xlsx,.xls"
                    :http-request="importExcel" style="float: left;margin: 0 5px">
                <el-button size="small"  title="导入excel" type="primary" icon="el-icon-upload" circle ></el-button>
            </el-upload>
            <el-button style="float: left;margin: 0 5px" size="small" v-if="hasExportStructure" title="导出excel" type="success" icon="el-icon-download" circle @click="showExport()"></el-button>

            <a-breadcrumb :style="{paddingLeft:'10px',height:'32px',lineHeight:'32px',float:'right',marginRight:'20px'}">
                <a-breadcrumb-item v-for="(item,index) in breadCrumb" :key="index">{{ item }}</a-breadcrumb-item>
            </a-breadcrumb>



        </a-row>
        <!--列表-->
        <el-table :data="tableData" style="width: 100%;overflow-y: auto;padding-left:10px;" :height="tableHeight">
            <el-table-column v-for="(structure,structureIndex) in tableStructure" :key="structureIndex"
                             :label="structure.label"
                             :width="structure.width">
                <template slot-scope="scope" v-if="showData">
                    <component :is="'LuckyTable'+structure.component" :id="scope.row.hasOwnProperty('id')?scope.row.id:0" :value="scope.row[structure.field]" :rowValue="scope.row" :structure="structure"></component>
                </template>
            </el-table-column>

        </el-table>
        <!--列表-->
        <!--分页-->
        <el-pagination
                v-show="dataCount > 0"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                class="paginationClass"
                :current-page="currentPage"
                :page-sizes="[6, 12, 18, 24, 30]"
                :page-size="pageSize"
                background
                layout="total, sizes, prev, pager, next, jumper"
                :total="dataCount">
        </el-pagination>
        <!--分页-->
        <!--搜索-->
        <a-drawer
                title="搜索&查询"
                :width="360"
                :visible="search"
                placement="left"
                :body-style="{ paddingBottom: '80px' }"
                @close="closeSearch(false)">
            <a-form layout="vertical" hide-required-mark>
                <a-form-item :label="col.label" v-for="(col,colIndex) in searchStructure" :key="colIndex">
                    <component
                            :key="timer+ roundNumber +'colIndex'+ colIndex"
                            :is="'Lucky'+col.component"
                            :showWarning="false"
                            :structure="col"
                    />
                </a-form-item>

            </a-form>
            <div class="search_footer_class">
                <el-button size="small" type="info" :style="{ marginRight: '8px' }" @click="closeSearch(true)">
                    清空
                </el-button>
            </div>
        </a-drawer>
        <!--搜索-->
        <!--导出-->
        <a-drawer
                title="选择需要导出的数据"
                :width="260"
                :visible="showExportStructure"
                :body-style="{ paddingBottom: '80px',paddingLeft: '60px' }"
                @close="closeExport">
            <a-form layout="vertical" hide-required-mark>
                <a-form-item :label="col.label" v-for="(col,colIndex) in exportStructure.fields" :key="colIndex">
                    <component
                            :key="timer+ roundNumber +'colIndex'+ colIndex"
                            is="LuckySwitch"
                            :structure="col"
                    />
                </a-form-item>
            </a-form>
            <div class="search_footer_class" :style="{textAlign:'center'}">
                <el-button size="small" type="primary" :style="{ marginRight: '8px' }" @click="exportExcel">
                    导出
                </el-button>
            </div>
        </a-drawer>
        <!--导出-->
    </fragment>
</template>

<script>

    import {
        LuckyTableVideo,LuckyTableOrder,
        LuckyTableVarchar,LuckyTableIcon,LuckyTableButtons,LuckyTableInput, LuckyTableImage,LuckyTableImages,LuckyTableSelect,LuckyTableSwitch,LuckyTableNumber
    } from "@/components/table-components"

    import {
        LuckyInput,LuckyNumber,LuckyRadio,LuckySelect,LuckyDate,LuckyTime,LuckyCascader,LuckySwitch
    } from "@/components/table-search-components"

    export default {
        name:'LuckyTable',
        props: ['componentData','componentIndex','componentStatus','breadCrumb'],
        components: {
            LuckyTableVideo,LuckyTableOrder,
            LuckyTableVarchar,LuckyTableIcon,LuckyTableButtons,LuckyTableInput,LuckyTableImage,LuckyTableImages,LuckyTableSelect,LuckyTableSwitch,LuckyTableNumber,
            LuckyInput,LuckyNumber,LuckyRadio,LuckySelect,LuckyDate,LuckyTime,LuckyCascader,LuckySwitch
        },
        data(){
            return {
                tableStructure:{},      //列表页结构
                recordsUrl:'',          //获取数据的链接
                tableData:[] ,          //列表上的数据
                dataCount:0,            //数据总数
                currentPage:1,          //当前页码
                pageSize:6,             //每页数量
                showData:true,          //是否展示数据

                showCloseButton:false,   //是否显示返回按钮
                tableButtons:{},          //按钮组

                hasSearchStructure:false, //是否有搜索
                searchStructure: {},      //搜索结构
                searchButtonColor:'info', //搜索按钮的颜色
                search: false,            //是否展开搜索
                timer:'',
                roundNumber:'',

                hasExportStructure:false, //是否有导出excel
                hasImport:false,          //是否有导入excel
                importRequestUrl:'',      //导入excel的请求地址
                exportStructure: {},      //导出excel结构
                showExportStructure: false, //是否展开导出
            }
        },
        watch: {
            //bread面包屑控制
            componentStatus(newName, oldName) {
                let that = this;
                if(!oldName && newName){
                    that.refresh()
                }
            },
        },
        computed:{
            //初始化table高度
            tableHeight:function () {
                let height= document.body.clientHeight;
                return (height - 290)
            }
        },
        created() {
            let that = this
            //是否展示返回按钮
            that.showCloseButton = that.componentIndex ? true : false ;
            //获取table页面结构

            that.$ajax.post(that.componentData.requestUrl, that.componentData.obj)
                .then(res=>{
                    //列表
                    that.tableStructure = res.structure;
                    //搜索
                    if(res.search.length > 0){
                        that.searchStructure = res.search;
                        that.hasSearchStructure = true;
                    }
                    //导出
                    if(res.export.hasOwnProperty('fields') && res.export.fields.length > 0){
                        that.exportStructure = res.export;
                        that.hasExportStructure = true;
                    }
                    //导入
                    that.hasImport = res.import.status
                    that.importRequestUrl = res.import.requestUrl

                   //按钮组
                    if(res.tableButtons !== false){
                        that.tableButtons = res.tableButtons;
                    }

                    that.recordsUrl = res.recordsUrl;
                    that.timer = new Date().getTime()
                    that.roundNumber = Math.floor(Math.random() * (9999 - 1 + 1)) + 1

                    that.getRecords()

                })
        },
        methods: {
            //展开导出抽屉
            showExport(){
                this.showExportStructure = true;
            },
            //关闭导出抽屉
            closeExport() {
                this.showExportStructure = false;
            },
            //展开搜索抽屉
            showSearch() {
                this.search = true;
            },
            //关闭搜索抽屉
            closeSearch(clear = false) {
                if(clear == true){
                    this.searchStructure.forEach((item,index)=>{
                        if(item.component == 'Checkbox' || item.component == 'Cascader')
                            this.searchStructure[index]['value'] = [];
                        else
                            this.searchStructure[index]['value'] = '';
                    })
                }
                this.searchButtonColor = 'info'
                this.searchStructure.forEach((item,index)=>{
                    if(item.value.length > 0)
                        this.searchButtonColor = 'warning'
                })


                this.search = false;

                this.tableData = []
                this.showData = false
                this.getRecords()
            },
            //返回
            closeComponent(){
                let that = this;
                that.$parent.closeComponent(that.componentIndex)
            },
            //按钮操作
            buttonHandle(button){
                let that = this;
                let obj;
                if(that.componentData.hasOwnProperty('obj')){
                    obj = that.componentData.obj;
                }
                that.tableHandles(button,obj)
            },
            //刷新
            refresh(){
                let that = this;
                that.tableData = []
                that.showData = false
                that.getRecords()
            },
            //获取数据
            getRecords(){
                let that = this;
                //判断是否是从其他页面跳转过来，并带来参数
                let obj;
                if(that.componentData.hasOwnProperty('obj')){
                    obj = Object.assign({searchConditions:that.searchStructure,current_page:that.currentPage,page_size:that.pageSize},that.componentData.obj);
                }
                else
                    obj = {searchConditions:that.searchStructure,current_page:that.currentPage,page_size:that.pageSize};
                that.$ajax.post(that.recordsUrl, obj)
                    .then(res=>{

                        that.tableData = res.list;
                        that.dataCount = res.count;
                        that.showData = true
                        if(that.tableData.length === 0 && that.currentPage>0){
                            that.currentPage--
                            that.getRecords()
                        }

                    })
            },
            //操作分页
            handleSizeChange(val){
                this.currentPage = 1;
                this.pageSize = val;
                this.showData = false
                this.getRecords();

            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.showData = false
                this.getRecords();
            },
            //列表按钮操作
            tableHandles(handleInfo,obj){
                let that = this
                //直接操作
                if(handleInfo.handleType == 'request'){
                    if(handleInfo.warning){
                        that.$messageBox.confirm(handleInfo.warning, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            that.$ajax.post(handleInfo.requestUrl, obj)
                                .then(res=>{
                                    that.refresh();
                                    if(res.length > 0)
                                        that.$message.success(res)
                                })
                        }).catch(action => {
                        });
                    }else {
                        that.$ajax.post(handleInfo.requestUrl, obj)
                            .then(res=>{
                                that.refresh();
                                if(res.length > 0)
                                    that.$message.success(res)
                            })
                    }

                }
                //跳转
                if(handleInfo.handleType == 'jump'){
                    handleInfo.obj = obj
                    that.$parent.openComponent(handleInfo)
                }

                //下载
                if(handleInfo.handleType == "download"){
                    that.$messageBox.confirm('确认下载？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        that.$ajax.post('/'+ handleInfo.requestUrl, obj).then(res=>{
                            that.$upload.downloadFile(res)
                        })
                    }).catch(action => {
                    });
                }
            },
            //导出excel
            exportExcel(){
                let that = this;
                //判断是否是从其他页面跳转过来，并带来参数
                let obj;
                if(that.componentData.hasOwnProperty('obj')){
                    obj = Object.assign({searchConditions:that.searchStructure,exportStructure:that.exportStructure},that.componentData.obj);
                }
                else
                    obj = {searchConditions:that.searchStructure,exportStructure:that.exportStructure};
                that.$ajax.post(that.exportStructure.requestUrl, obj)
                    .then(res=>{
                        that.$upload.downloadFile(res.url)
                        that.showExportStructure = false;
                    })

            },
            //导入excel
            importExcel(param){
                let that = this;
                let fileObj = param.file;
                let formData = new FormData();
                formData.append("file", fileObj);
                that.$ajax.post(that.importRequestUrl, formData,true)
                    .then(res=>{
                        that.$messageBox.alert(res,"导入结果",{confirmButtonText: '确定'})
                        that.getRecords();
                    })
            },

        }
    }
</script>

<style scoped>
    .paginationClass{
        margin-top: 10px;
    }
    .buttons-class{
        margin-left: 10px;
        margin-top: 10px;
        height: 40px;
        border-bottom:1px solid #d9d9d9;
    }
    .search_footer_class{
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        padding: 0 16px;
        height: 48px;
        line-height: 48px;
        background: #fff;
        text-align: right;
        z-index: 1;
    }

</style>