<template>
    <fragment>

        <el-row >
            <el-col :span="24" style="text-align:center;margin-top: 15vh;font-size: 60px;line-height: 100px">
                欢迎使用<br>
                {{system_name}}
            </el-col>
        </el-row>

    </fragment>
</template>

<script>
    export default {
        name:'LuckyIndex',
        data(){
            return {
                system_logo:'logo.png',                  //项目LOGO
                system_name:'Lucky3.2',                  //项目名称
            }
        },
        created() {
            this.$ajax.post('/auth/Admin/afterLogin', {})
                .then(res=>{
                    this.system_name = res.system_name?res.system_name:'Lucky2.0';
                    this.system_logo = res.system_logo;
                })
        },
        methods:{

        }
    }
</script>

<style >
    /*.el-card__body{*/
    /*    padding-top: 0;*/
    /*}*/
    .el-card{
        height: 110px !important;
    }
    .datas{
        overflow-y: auto;
    }
</style>